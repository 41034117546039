import React, { useCallback, useMemo, useState } from "react";
import { WithCsvRowNumber, CsvUploadResult, RowIdentifierAndErrors } from "utils/csv-utils";
import { isLoaded, LoadingValue } from "utils/utility-types";
import { useAlert } from "../../../context/global-alert/use-alert-context";
import { useCSVReader } from "react-papaparse";
import { ParseConfig, ParseResult } from "papaparse";
import { Loader } from "../../loader/loader";
import {
  Button,
  ButtonProps,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ConfirmationDialog } from "../../confirmation-dialog/confirmation-dialog";
import { SplitButton, SplitButtonOption } from "../../split-button";
import { HttpServiceError } from "adl-service/http-service-error";

export function CsvUploadButton<T>({
  rowIdentifierFieldName,
  onParseCsv,
  onSuccessfulUpload,
  csvTemplate,
  csvInstructions,
  dynamicTyping = true,
  ...buttonProps
}: {
  rowIdentifierFieldName?: string;
  onParseCsv(result: ParseResult<Record<string, string>>): Promise<CsvUploadResult<T>>;
  onSuccessfulUpload(uploaded: WithCsvRowNumber<T>[]): Promise<void>;
  dynamicTyping?: ParseConfig["dynamicTyping"];
  csvTemplate?: string;
  csvInstructions?: string;
} & ButtonProps) {
  return (
    <CsvUploadButtonInner
      rowIdentifierFieldName={rowIdentifierFieldName}
      onParseCsv={onParseCsv}
      onSuccessfulUpload={onSuccessfulUpload}
      csvReaderConfig={{ header: true, dynamicTyping: dynamicTyping }}
      csvTemplate={csvTemplate}
      csvInstructions={csvInstructions}
      {...buttonProps}
    />
  );
}

export function CsvUploadButtonWithArrayData<T>({
  rowIdentifierFieldName,
  onParseCsv,
  onSuccessfulUpload,
  csvTemplate,
  csvInstructions,
  ...buttonProps
}: {
  rowIdentifierFieldName?: string;
  onParseCsv(result: ParseResult<string[]>): Promise<CsvUploadResult<T>>;
  onSuccessfulUpload(uploaded: WithCsvRowNumber<T>[]): Promise<void>;
  csvTemplate?: string;
  csvInstructions?: string;
} & ButtonProps) {
  return (
    <CsvUploadButtonInner
      rowIdentifierFieldName={rowIdentifierFieldName}
      onParseCsv={onParseCsv}
      onSuccessfulUpload={onSuccessfulUpload}
      csvReaderConfig={{ header: false, dynamicTyping: false }}
      csvTemplate={csvTemplate}
      csvInstructions={csvInstructions}
      {...buttonProps}
    />
  );
}

function CsvUploadButtonInner<T>({
  rowIdentifierFieldName,
  onParseCsv,
  onSuccessfulUpload,
  csvReaderConfig,
  csvTemplate,
  csvInstructions,
  ...buttonProps
}: {
  rowIdentifierFieldName?: string;
  onParseCsv(result: ParseResult<Record<string, string> | string[]>): Promise<CsvUploadResult<T>>;
  onSuccessfulUpload(uploaded: WithCsvRowNumber<T>[]): Promise<void>;
  csvReaderConfig: {
    header: boolean;
    dynamicTyping: ParseConfig["dynamicTyping"];
  };
  csvTemplate?: string;
  csvInstructions?: string;
} & ButtonProps) {
  const [loadingUploadResult, setLoadingUploadResult] = useState<LoadingValue<CsvUploadResult<T>> | undefined>(
    undefined,
  );
  const [showAlert] = useAlert();
  const { CSVReader } = useCSVReader();

  // Handles errors thrown during the upload process
  const handleError = useCallback(
    async (error: unknown) => {
      setLoadingUploadResult(undefined);
      const body = error instanceof HttpServiceError ? error.publicMessage : String(error);
      await showAlert({
        title: "Failed to upload rows, please try again.",
        body: body,
        severity: "error",
      });
    },
    [showAlert],
  );

  const onSuccess = useCallback(
    async (uploaded: WithCsvRowNumber<T>[]) => {
      try {
        await onSuccessfulUpload(uploaded);
        setLoadingUploadResult(undefined);
        // If request was successful and returned no errors, show success message
        await showAlert({
          title: "Successful upload",
          body: "All rows have been successfully uploaded.",
          severity: "success",
        });
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, onSuccessfulUpload, showAlert],
  );

  const onUpload = useCallback(
    async (result: ParseResult<Record<string, string>>) => {
      setLoadingUploadResult({ state: "loading" });

      try {
        const loadingUploadFile = await onParseCsv(result);

        if (loadingUploadFile.kind === "errors") {
          // If request was successful but returned errors, show the errors
          setLoadingUploadResult({ state: "success", value: loadingUploadFile });
          return;
        }

        if (loadingUploadFile.kind === "successes") {
          await onSuccess(loadingUploadFile.value);
        }
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, onParseCsv, onSuccess],
  );

  const splitButtonProps = useMemo(() => {
    const options = [
      csvTemplate && { key: "csvTemplate", label: "Download Template" },
      csvInstructions && { key: "csvInstructions", label: "Download Instructions" },
    ].filter(Boolean) as SplitButtonOption<string>[]; // Filter out null/undefined

    const onMenuItemClick = (key: string) => {
      const url = key === "csvTemplate" ? csvTemplate : key === "csvInstructions" ? csvInstructions : null;
      if (url) window.open(url);
    };

    return { options, onMenuItemClick };
  }, [csvInstructions, csvTemplate]);
  return (
    <>
      {loadingUploadResult && (
        <Loader fullScreen loadingStates={[loadingUploadResult]}>
          {isLoaded(loadingUploadResult) && loadingUploadResult.value.kind === "errors" && (
            <UploadErrorsDialog
              rowIdentifierFieldName={rowIdentifierFieldName}
              errors={loadingUploadResult.value.value}
              onClose={() => {
                setLoadingUploadResult(undefined);
              }}
            />
          )}
        </Loader>
      )}
      <CSVReader
        onUploadAccepted={onUpload}
        config={{
          skipEmptyLines: "greedy",
          ...csvReaderConfig,
        }}>
        {({ getRootProps }: { getRootProps(): ButtonProps }) => (
          <>
            {csvInstructions || csvTemplate ? (
              <SplitButton {...getRootProps()} {...buttonProps} {...splitButtonProps} />
            ) : (
              <Button {...getRootProps()} {...buttonProps}>
                {buttonProps.children}
              </Button>
            )}
          </>
        )}
      </CSVReader>
    </>
  );
}

interface UploadErrorsDialogProps {
  rowIdentifierFieldName?: string;
  errors: WithCsvRowNumber<RowIdentifierAndErrors>[];
  onClose(): void;
}

const UploadErrorsDialog = ({ rowIdentifierFieldName, errors, onClose }: UploadErrorsDialogProps) => {
  return (
    <ConfirmationDialog
      maxWidth="md"
      fullWidth
      open={true}
      title="Your uploaded CSV file has some errors."
      acceptAction={{ title: "Got it", onClick: onClose }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{rowIdentifierFieldName ?? "Row Identifier"}</TableCell>
            <TableCell align="right">CSV row</TableCell>
            <TableCell>Errors</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {errors.map((row, id) => {
            const rowNumberKey = `${row.rowNumber}-${id}`;
            return (
              <TableRow key={rowNumberKey}>
                <TableCell>{row.value.rowIdentifier ?? ""}</TableCell>
                <TableCell align="right">{isNaN(row.rowNumber) ? "-" : row.rowNumber}</TableCell>
                <TableCell>
                  <Stack spacing={2}>
                    {row.value.errors.map((err, i) => (
                      <Typography key={err + i + rowNumberKey}>{err}</Typography>
                    ))}
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </ConfirmationDialog>
  );
};
