import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  ButtonProps,
  Chip,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { WithDbId } from "adl-gen/common/db";
import {
  Currency,
  NewDealRequestType,
  Product,
  TopLevelUnitType,
  snTopLevelUnitType,
  valuesCurrency,
  NumberOfUnits,
} from "adl-gen/ferovinum/app/db";
import {
  EXISTING_STOCK_NEW_DEAL_REQUEST_CSV_TEMPLATE_URL,
  NEW_STOCK_NEW_DEAL_REQUEST_CSV_TEMPLATE_URL,
} from "components/assets/url";
import { useAlert } from "components/context/global-alert/use-alert-context";
import { ConfirmationDialog } from "components/widgets/confirmation-dialog/confirmation-dialog";
import { GroupHeaderCell } from "components/widgets/group-header-cell";
import { InfoTooltip } from "components/widgets/info-tooltip/info-tooltip";
import { CurrencyInputFastField } from "components/widgets/inputs/currency-input/currency-input-fast-field";
import { UnitsInputFastField } from "components/widgets/inputs/units-input/units-input-fast-field";
import { Link } from "components/widgets/link/link";
import { Loader } from "components/widgets/loader/loader";
import { PaginatedTable } from "components/widgets/paginated-table/paginated-table";
import { ProductSummary, ProductSummaryProps } from "components/widgets/product-summary/product-summary";
import { FieldArray, FormikProps, FormikProvider, getIn, useFormik } from "formik";
import { ParseResult } from "papaparse";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCSVReader } from "react-papaparse";
import { getFormLabelForUnionField, getTopLevelForUnitType } from "utils/adl-utils";
import { getCurrencySymbol, isValidUnitType, titleCase, unitTypeToString } from "utils/conversion-utils";
import { RowIdentifierAndErrors, WithCsvRowNumber } from "utils/csv-utils";
import { assertNotUndefined } from "utils/hx/util/types";
import {
  VesselCapacity,
  getVesselCapacity,
  isSinglesUnitType,
  unitsLabelForUnitType,
  BulkQuantityUnitType,
  numberOfUnitsForUnitType,
} from "utils/model-utils";
import { keysToRecord } from "utils/type-utils";
import { LoadingValue, isLoaded, isLoading } from "utils/utility-types";
import { array, number, object, string } from "yup";
import {
  CsvDataField,
  CsvProductLineItemData,
  ProcessedCsvProductData,
  PRODUCT_CODE_COLUMN_NAME,
} from "../../../../../service/csv-product-data-processing-types";
import { getSimpleProductLineItemQuantityAndPriceSchema } from "../../../../../service/simple-product-csv-parser";
import { useSelectedEntityIsWholesaler } from "../../../../../utils/procurement-ui-utils";
import { PortalPageContentHeader } from "../../../../layouts/portal-page-content-header/portal-page-content-header";
import { PortalPageContent } from "../../../../layouts/portal-page-content/portal-page-content";
import { ScrollToFieldError } from "../../../../widgets/common/form/scroll-to-field-error/scroll-to-field-error";
import { InvisibleCell } from "../../../../widgets/common/table/table-cell/invisible-cell";
import { NewDealRequestFlowStepper } from "../../../../widgets/flow-stepper/new-deal-request-flow-stepper";
import {
  OrganisationProductSummary,
  OrganisationProductSummaryProps,
} from "../../../../widgets/organisation-product-summary/organisation-product-summary";
import { ProductEditorDialog } from "../../../../widgets/product-data/product-editor-dialog";
import { ProductSearch } from "../../../../widgets/product-data/product-search";
import {
  NewProductData,
  ProductLineItemData,
  ProductLineItemFormData,
} from "../organisation-create-new-deal-request/organisation-create-new-deal-request-page";
import { isNewProduct, isNewProductData, productLineItemIsSingles } from "./organisation-new-deal-request-setup-page";
import { CurrencyRenderer } from "components/widgets/currency-renderer/currency-renderer";
import { Decimal } from "adl-gen/ferovinum/app/types";
import Big from "big.js";

export interface OrganisationNewDealRequestSetupPageViewProps {
  variant: NewDealRequestType;
  approvedSupplierCurrencies: Currency[];
  initialFormValues: SetupNewDealRequestFormValues;
  searchProducts: (term: string) => Promise<WithDbId<Product>[]>;
  navigateBackToCreateNewDealRequest: (values: SetupNewDealRequestFormValues) => void;
  onNext: (values: SetupNewDealRequestFormValues) => void;
  onValidateProductCode(productCode: string): Promise<boolean>;
  onClickUploadProducts(parseResult: ParseResult<string[]>): Promise<ProcessedCsvProductData | undefined>;
}

interface NonSingleGroup {
  topLevelUnitType: TopLevelUnitType;
  product: ProductLineItemFormData;
  idx: number;
  quantityUnit: BulkQuantityUnitType;
}

export const OrganisationNewDealRequestSetupPageView = ({
  variant,
  approvedSupplierCurrencies,
  initialFormValues,
  searchProducts,
  navigateBackToCreateNewDealRequest,
  onNext,
  onValidateProductCode,
  onClickUploadProducts,
}: OrganisationNewDealRequestSetupPageViewProps) => {
  const orgIsWholesaler = useSelectedEntityIsWholesaler();
  const [addProductDialogOpen, setProductEditorDialogOpen] = useState(false);
  const [editProductData, setEditProductData] = useState<NewProductData | undefined>();
  const productSchema = getSimpleProductLineItemQuantityAndPriceSchema(variant === "newStock");
  const validationSchema = object().shape({
    currency: string().oneOf(valuesCurrency).required(),
    selectedProducts: array()
      // Note: (typescript bug): https://github.com/jquense/yup/issues/1283
      // @ts-ignore
      .of(productSchema)
      .min(1),
    discount: number().min(0).max(100),
  });
  const form = useFormik<SetupNewDealRequestFormValues>({
    initialValues: {
      currency: initialFormValues.currency,
      discount: initialFormValues.discount,
      selectedProducts: initialFormValues.selectedProducts ?? [],
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onNext,
  });

  const localUniqueProductCodes = useMemo(() => {
    return form.values.selectedProducts.map(p => p.productCode);
  }, [form.values.selectedProducts]);
  const onEditProduct = useCallback(
    (productCode: string) => {
      const editProduct = form.values.selectedProducts.find(p => p.productCode === productCode);
      if (editProduct && isNewProduct(editProduct)) {
        setEditProductData(editProduct.value);
        setProductEditorDialogOpen(true);
      }
    },
    [form.values.selectedProducts],
  );

  const onAddProduct = useCallback(
    (product: WithDbId<Product> | NewProductData) => {
      let productLineItemData: ProductLineItemFormData;
      if (isNewProductData(product)) {
        productLineItemData = {
          kind: "new",
          productCode: product.code,
          value: product,
        };
      } else {
        productLineItemData = { kind: "existing", productCode: product.value.code, value: product };
      }
      form.setFieldValue("selectedProducts", [...form.values.selectedProducts, productLineItemData]);
    },
    [form],
  );

  const updateSelectedProduct = useCallback(
    (productCode: string, product: NewProductData) => {
      const index = form.values.selectedProducts.findIndex(p => p.productCode === productCode);
      if (index > -1 && isNewProduct(form.values.selectedProducts[index])) {
        const newSelectedProducts = [...form.values.selectedProducts];
        const oldProduct = form.values.selectedProducts[index];
        newSelectedProducts.splice(index, 1, {
          kind: "new",
          productCode: product.code,
          value: product,
          numberOfUnits: oldProduct.numberOfUnits,
          numberOfFreeUnits: oldProduct.numberOfFreeUnits,
          purchasePrice: oldProduct.purchasePrice,
          individualDiscountPct: oldProduct.individualDiscountPct,
        });
        form.setFieldValue("selectedProducts", newSelectedProducts);
      }
    },
    [form],
  );

  // Note existing stock only supports settlement currency
  const supportsMultiCurrency = variant === "newStock" && approvedSupplierCurrencies.length > 1;

  const onUploadProducts = useCallback(
    async (products: ProductLineItemData[]) => {
      const existingProductCodes = new Set(form.values.selectedProducts.map(p => p.productCode));
      const newSelectedProducts = [
        ...form.values.selectedProducts,
        ...products
          .filter(p => !existingProductCodes.has(p.productCode))
          .map(p => ({
            ...p,
            numberOfUnits: p.numberOfUnits?.value,
            numberOfFreeUnits: p.numberOfFreeUnits?.value,
            individualDiscountPct: p.individualDiscountPct || "0",
            bulkQuantityUnitType: p.numberOfUnits?.kind,
          })),
      ];

      // Note (Nicole): Using setTimeout here is a hack. The loading animation won't render without it.
      const setFieldValue = new Promise((resolve, _) => {
        setTimeout(() => {
          resolve(form.setFieldValue("selectedProducts", newSelectedProducts, true));
        }, 500);
      });

      await setFieldValue;
    },
    [form],
  );

  const singlesProducts = form.values.selectedProducts
    .map((product, idx) => ({ product, idx }))
    .filter(p => productLineItemIsSingles(p.product));
  const filteredProducts = form.values.selectedProducts
    .map((product, idx) => ({ product, idx }))
    .filter(p => !productLineItemIsSingles(p.product));

  const groupedNonSingles = filteredProducts.reduce<Record<string, NonSingleGroup[]>>((acc, p) => {
    const unitType = isNewProduct(p.product) ? p.product.value.unitType : p.product.value.value.unitType;
    const topLevelUnitType = getTopLevelForUnitType(unitType);
    const numberOfUnits: NumberOfUnits = numberOfUnitsForUnitType(
      unitType,
      p.product.numberOfUnits ?? 0,
      p.product.bulkQuantityUnitType,
    );
    const quantityUnit = numberOfUnits.kind as BulkQuantityUnitType;

    const key = `${topLevelUnitType}-${quantityUnit}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push({ topLevelUnitType, product: p.product, idx: p.idx, quantityUnit });

    return acc;
  }, {});

  const nonSingles: NonSingleGroup[][] = Object.values(groupedNonSingles);

  return (
    <PortalPageContent
      header={
        <OrganisationPurchaseOrderSetupHeader
          variant={variant}
          supportsMultiCurrency={supportsMultiCurrency}
          orgIsWholesaler={orgIsWholesaler}
        />
      }>
      <Stack height="100%" justifyContent="space-between" spacing={4}>
        <Stack spacing={4} minHeight={0}>
          {supportsMultiCurrency && (
            <SetupStep question="A - What currency would you like your order to be placed in?">
              <Box width="40%">
                <Select<Currency>
                  fullWidth
                  name={"currency"}
                  value={form.values.currency}
                  error={form.touched.currency && Boolean(form.errors.currency !== undefined)}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}>
                  {approvedSupplierCurrencies.map(currency => (
                    <MenuItem key={currency} value={currency}>{`${currency} (${getCurrencySymbol(
                      currency,
                    )})`}</MenuItem>
                  ))}
                </Select>
              </Box>
            </SetupStep>
          )}
          <SetupStep
            question={`${supportsMultiCurrency ? "B -" : ""} ${
              variant === "newStock"
                ? "What product(s) would you like to order?"
                : "Which stock would you like to onboard?"
            }`}>
            <Stack spacing={4}>
              <Stack spacing={4} direction="row" alignItems="center">
                <Box width="40%">
                  <ProductSearch
                    searchProducts={searchProducts}
                    onSelectProduct={onAddProduct}
                    selectedProductCodes={new Set(localUniqueProductCodes)}
                    onAddNewProduct={() => setProductEditorDialogOpen(true)}
                  />
                </Box>
                <Divider sx={{ width: theme => theme.spacing(10) }}>OR</Divider>
                <ProductsCsvUpload
                  onUploadProducts={onUploadProducts}
                  onClickUploadProducts={onClickUploadProducts}
                  isNewStock={variant === "newStock"}
                />
              </Stack>
              <ScrollToFieldError isValid={form.isValid} submitCount={form.submitCount} errors={form.errors} />
              <FormikProvider value={form}>
                {form.values.selectedProducts.length > 0 && (
                  <>
                    {variant === "existingStock" ? (
                      <ExistingStockSelectedProductsTable
                        form={form}
                        singles={singlesProducts}
                        nonSingles={nonSingles}
                        onEditProduct={onEditProduct}
                        orgIsWholesaler={orgIsWholesaler}
                      />
                    ) : (
                      <NewStockSelectedProductsTable
                        form={form}
                        singles={singlesProducts}
                        nonSingles={nonSingles}
                        onEditProduct={onEditProduct}
                      />
                    )}
                  </>
                )}
              </FormikProvider>
            </Stack>
          </SetupStep>
        </Stack>
        <ActionButtons
          onBack={() => navigateBackToCreateNewDealRequest(form.values)}
          onNext={async () => {
            await form.submitForm();
          }}
          disabled={form.values.selectedProducts.length === 0}
        />
      </Stack>
      {/* We want to reset the form every time we reopen this product dialog */}
      {addProductDialogOpen && (
        <ProductEditorDialog
          onClose={() => {
            setProductEditorDialogOpen(false);
            setEditProductData(undefined);
          }}
          onAdd={productData => {
            setProductEditorDialogOpen(false);
            onAddProduct(productData);
          }}
          onUpdate={(code, productData) => {
            setProductEditorDialogOpen(false);
            updateSelectedProduct(code, productData);
            setEditProductData(undefined);
          }}
          localUniqueProductCodes={localUniqueProductCodes}
          initialValues={editProductData}
          onValidateProductCode={onValidateProductCode}
        />
      )}
    </PortalPageContent>
  );
};

const OrganisationPurchaseOrderSetupHeader = ({
  variant,
  supportsMultiCurrency,
  orgIsWholesaler,
}: {
  variant: NewDealRequestType;
  supportsMultiCurrency: boolean;
  orgIsWholesaler: boolean;
}) => {
  return (
    <PortalPageContentHeader
      variant="split"
      title="Order details"
      subtitles={[
        {
          text:
            variant === "newStock"
              ? `Input your order ${supportsMultiCurrency ? "currency," : ""} products, purchase quantity and price.`
              : `Input your order products, purchase quantity and ${
                  orgIsWholesaler ? "purchase" : "lowest wholesale"
                } price.`,
        },
      ]}
      right={<NewDealRequestFlowStepper variant={variant} activeStep={1} />}
    />
  );
};

interface SelectedProductsTableProps {
  singles: { product: ProductLineItemFormData; idx: number }[];
  nonSingles: NonSingleGroup[][];
  form: FormikProps<SetupNewDealRequestFormValues>;
  onEditProduct: (productCode: string) => void;
  orgIsWholesaler?: boolean;
}

const calculateSubTotal = (product: ProductLineItemFormData) => {
  if (!product) return 0; // Hotfix for an intermittent issue where product is undefined when deleting from the table.
  const purchasePrice = product.clientSpecifiedPurchasePrice || 0;
  const units = product.numberOfUnits || 0;
  const individualDiscountPct = product.individualDiscountPct || 0;
  return calculateDiscountedTotal(individualDiscountPct, new Big(purchasePrice).times(units));
};

const calculateDiscountedTotal = (discount: number, netSubtotal: Big) => {
  if (discount < 0 || discount > 100) return 0;
  if (!discount) return netSubtotal;

  Big.RM = Big.roundHalfEven; // Round half to even mode
  Big.DP = 16; // DP 16

  const totalDiscount = new Big(discount).div(100);
  return netSubtotal.minus(netSubtotal.times(totalDiscount));
};

const NewStockSelectedProductsTable = ({ singles, nonSingles, form, onEditProduct }: SelectedProductsTableProps) => {
  const commonInputProps: Partial<TextFieldProps> = {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      form.handleChange(e);
      // reset field error for the whole row
      const fieldName = e.target.name;
      form.setFieldError(fieldName.substring(0, fieldName.lastIndexOf(".")), undefined);
    },
    onBlur: form.handleBlur,
  };
  const [selectedColumns, setSelectedColumns] = useState<string[]>(determineInitialColumns(form.values));

  // Function to determine initial columns
  function determineInitialColumns(initialValues: SetupNewDealRequestFormValues): string[] {
    const initialColumns: string[] = [];
    // Check the form's initial values and determine which columns to open
    if (
      initialValues.selectedProducts.some(
        product => product.numberOfFreeUnits !== 0 && product.numberOfFreeUnits !== undefined,
      )
    ) {
      initialColumns.push("freeStock");
    }
    if (
      initialValues.selectedProducts.some(
        product => product.individualDiscountPct !== 0 && product.individualDiscountPct !== undefined,
      )
    ) {
      initialColumns.push("individualDiscountPct");
    }
    if (initialValues.discount && Number(initialValues.discount) !== 0) {
      initialColumns.push("discountRow");
    }
    return initialColumns;
  }

  const handleColumnsToggle = useCallback(
    (_event: React.MouseEvent<HTMLElement>, newSelectedColumns: string[]) => {
      const removedColumns = selectedColumns.filter(column => !newSelectedColumns.includes(column));

      // Function to reset product fields
      const resetProductFields = (product: ProductLineItemFormData, fieldsToReset: string | string[]) => {
        const newProduct = { ...product };
        if (fieldsToReset.includes("freeStock")) {
          newProduct.numberOfFreeUnits = undefined;
        }
        if (fieldsToReset.includes("individualDiscountPct")) {
          newProduct.individualDiscountPct = undefined;
        }
        return newProduct;
      };

      // If any columns have been deselected, reset their corresponding values
      if (removedColumns.length > 0) {
        form.setValues(prevValues => {
          // Reset the general discount if 'totalDiscount' was deselected
          // Make sure to convert the number to a string if the discount field is of type string
          const newDiscount = removedColumns.includes("discountRow") ? "" : prevValues.discount;

          return {
            ...prevValues,
            discount: newDiscount,
            selectedProducts: prevValues.selectedProducts.map(product => resetProductFields(product, removedColumns)),
          };
        });
      }

      // Update the selected columns state
      setSelectedColumns(newSelectedColumns);
    },
    [selectedColumns, form],
  );
  const netSubtotal = singles.reduce((acc, { product }) => acc.add(calculateSubTotal(product)), new Big(0));
  const discountAmount = calculateDiscountedTotal(Number(form.values.discount), netSubtotal);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "18px" }}>
        {singles && singles.length > 0 && (
          <ToggleButtonGroup value={selectedColumns} onChange={handleColumnsToggle} aria-label="text alignment">
            <ToggleButton value="freeStock">Free Stock</ToggleButton>
            <ToggleButton value="individualDiscountPct">Individual Discount</ToggleButton>
            <ToggleButton value="discountRow">Total Discount</ToggleButton>
          </ToggleButtonGroup>
        )}
      </div>
      <FieldArray
        name="selectedProducts"
        render={({ remove }) => (
          <Stack spacing={5}>
            {singles.length > 0 && (
              <TableContainer sx={{ overflowX: "unset" }}>
                <Table sx={{ backgroundColor: "transparent" }}>
                  <TableHead>
                    <TableRow>
                      <InvisibleCell />
                      {selectedColumns.includes("individualDiscountPct") && (
                        <GroupHeaderCell colSpan={3}>Paid stock</GroupHeaderCell>
                      )}
                      {!selectedColumns.includes("individualDiscountPct") && (
                        <GroupHeaderCell colSpan={2}>Paid stock</GroupHeaderCell>
                      )}
                      {selectedColumns.includes("freeStock") && <GroupHeaderCell>Free Stock</GroupHeaderCell>}
                    </TableRow>
                    <TableRow>
                      <SelectedProductTableCell align="left" emphasizeRightBorder>
                        Product
                      </SelectedProductTableCell>
                      <SelectedProductTableCell hideRightBorder>Unit purchase price</SelectedProductTableCell>
                      <SelectedProductTableCell emphasizeRightBorder>Number of units</SelectedProductTableCell>
                      {selectedColumns.includes("individualDiscountPct") && (
                        <SelectedProductTableCell emphasizeRightBorder>Discount</SelectedProductTableCell>
                      )}
                      {selectedColumns.includes("freeStock") && (
                        <SelectedProductTableCell emphasizeRightBorder>Number of units</SelectedProductTableCell>
                      )}
                      <SelectedProductTableCell emphasizeRightBorder>Subtotal</SelectedProductTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {singles.map(({ product, idx }) => (
                      <NewStockTableRow
                        key={product.productCode}
                        form={form}
                        product={product}
                        index={idx}
                        selectedColumns={selectedColumns}
                        onEdit={() => onEditProduct(product.productCode)}
                        onDelete={() => {
                          remove(idx);
                        }}
                      />
                    ))}
                  </TableBody>
                  <TableRow>
                    {Array.from({
                      length:
                        2 +
                        (selectedColumns.includes("freeStock") ? 1 : 0) +
                        (selectedColumns.includes("individualDiscountPct") ? 1 : 0),
                    }).map((_, index) => (
                      <SelectedProductTableCell key={index} hideRightBorder></SelectedProductTableCell>
                    ))}
                    <SelectedProductTableCell align="right" hideRightBorder>
                      Net Subtotal
                    </SelectedProductTableCell>
                    <SelectedProductTableCell hideRightBorder>
                      <CurrencyRenderer currency={form.values.currency} value={netSubtotal} />
                    </SelectedProductTableCell>
                  </TableRow>
                  {selectedColumns.includes("discountRow") && (
                    <TableRow>
                      {Array.from({
                        length:
                          2 +
                          (selectedColumns.includes("freeStock") ? 1 : 0) +
                          (selectedColumns.includes("individualDiscountPct") ? 1 : 0),
                      }).map((_, index) => (
                        <SelectedProductTableCell key={index} hideRightBorder></SelectedProductTableCell>
                      ))}
                      <SelectedProductTableCell align="right" hideRightBorder>
                        Net Subtotal Discount
                      </SelectedProductTableCell>
                      <TextField
                        name="discount"
                        type="number"
                        inputProps={{ min: 0, max: 100 }}
                        value={form.values.discount ?? "0"}
                        error={Boolean(form.errors.discount)}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        {...commonInputProps}
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "0.2em",
                          },
                        }}
                      />
                    </TableRow>
                  )}
                  {selectedColumns.includes("discountRow") && (
                    <TableRow>
                      {Array.from({
                        length:
                          2 +
                          (selectedColumns.includes("freeStock") ? 1 : 0) +
                          (selectedColumns.includes("individualDiscountPct") ? 1 : 0),
                      }).map((_, index) => (
                        <SelectedProductTableCell key={index} hideRightBorder></SelectedProductTableCell>
                      ))}
                      <SelectedProductTableCell align="right" hideRightBorder>
                        Discounted Net Subtotal
                      </SelectedProductTableCell>
                      <SelectedProductTableCell hideRightBorder>
                        <CurrencyRenderer currency={form.values.currency} value={discountAmount} />
                      </SelectedProductTableCell>
                    </TableRow>
                  )}
                </Table>
              </TableContainer>
            )}
            {nonSingles.map((group, index) => {
              return (
                <NonSinglesStockSelectedProductsTable
                  key={index}
                  topLevelUnitType={group[0].topLevelUnitType}
                  form={form}
                  onEditProduct={onEditProduct}
                  onDeleteProduct={remove}
                  products={group.map(item => ({
                    product: assertNotUndefined(item.product),
                    idx: item.idx,
                  }))}
                  quantityUnit={group[0].quantityUnit}
                />
              );
            })}
          </Stack>
        )}
      />
    </div>
  );
};

const NewStockTableRow = ({
  form,
  product,
  selectedColumns,
  onEdit,
  onDelete,
  index,
}: SelectedProductTableRowProps) => {
  const fieldName = (name: string) => `selectedProducts.${index}.${name}`;
  const unitType = isNewProduct(product) ? product.value.unitType : product.value.value.unitType;

  const commonInputProps: Partial<TextFieldProps> = {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // Original form handling
      form.handleChange(e);
      // reset field error for the whole row
      const fieldName = e.target.name;
      form.setFieldError(fieldName.substring(0, fieldName.lastIndexOf(".")), undefined);

      // Specific logic for copying clientSpecifiedPurchasePrice to purchasePrice
      if (fieldName.endsWith(".clientSpecifiedPurchasePrice")) {
        const newClientSpecifiedPurchasePrice = e.target.value;

        // Construct the field name for purchasePrice
        const purchasePriceFieldName = fieldName.replace("clientSpecifiedPurchasePrice", "purchasePrice");

        // Update the value of purchasePrice to match clientSpecifiedPurchasePrice
        form.setFieldValue(purchasePriceFieldName, newClientSpecifiedPurchasePrice, true);
      }
    },

    onBlur: form.handleBlur,
  };

  const emphasizeRightBorder = isSinglesUnitType(unitType);

  const errorPath = `selectedProducts[${index}].individualDiscountPct`;
  const fieldError = getIn(form.errors, errorPath);
  const fieldTouched = getIn(form.touched, errorPath);
  const isError = Boolean(fieldError && fieldTouched);
  const helperText = isError ? fieldError : "";

  return (
    <TableRow sx={{ backgroundColor: "common.white" }}>
      <SelectedProductTableCell emphasizeRightBorder={emphasizeRightBorder} align="left">
        <ProductCellContent product={product} onDelete={onDelete} onEdit={onEdit} />
      </SelectedProductTableCell>
      <SelectedProductTableCell hideRightBorder>
        <CurrencyInputFastField
          debounced
          currency={form.values.currency}
          name={fieldName("clientSpecifiedPurchasePrice")}
          value={form.values.selectedProducts[index]?.clientSpecifiedPurchasePrice ?? ""}
          helperText={
            form.errors.selectedProducts !== undefined &&
            form.errors.selectedProducts[index] !== undefined &&
            // @ts-ignore
            form.errors.selectedProducts[index]["clientSpecifiedPurchasePrice"]
          }
          error={
            form.touched.selectedProducts &&
            Boolean(
              form.errors.selectedProducts !== undefined &&
                form.errors.selectedProducts[index] !== undefined &&
                // @ts-ignore
                form.errors.selectedProducts[index]["clientSpecifiedPurchasePrice"],
            )
          }
          {...commonInputProps}
        />
      </SelectedProductTableCell>
      <SelectedProductTableCell emphasizeRightBorder={emphasizeRightBorder}>
        <UnitsInputFastField
          debounced
          unitType={unitType}
          name={fieldName("numberOfUnits")}
          value={form.values.selectedProducts[index]?.numberOfUnits ?? ""}
          helperText={
            form.errors.selectedProducts !== undefined &&
            form.errors.selectedProducts[index] !== undefined &&
            // @ts-ignore
            form.errors.selectedProducts[index]["numberOfUnits"]
          }
          error={
            form.touched.selectedProducts &&
            Boolean(
              form.errors.selectedProducts !== undefined &&
                form.errors.selectedProducts[index] !== undefined &&
                // @ts-ignore
                form.errors.selectedProducts[index]["numberOfUnits"],
            )
          }
          {...commonInputProps}
        />
      </SelectedProductTableCell>

      {selectedColumns.includes("individualDiscountPct") && (
        <SelectedProductTableCell emphasizeRightBorder>
          <TextField
            name={fieldName("individualDiscountPct")}
            type="number"
            inputProps={{ min: 0, max: 100 }}
            value={form.values.selectedProducts[index]?.individualDiscountPct ?? ""}
            error={isError}
            helperText={helperText}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            {...commonInputProps}
          />
        </SelectedProductTableCell>
      )}
      {selectedColumns.includes("freeStock") && (
        <SelectedProductTableCell emphasizeRightBorder={emphasizeRightBorder}>
          {isSinglesUnitType(unitType) ? (
            <UnitsInputFastField
              debounced
              unitType={unitType}
              name={fieldName("numberOfFreeUnits")}
              type="number"
              value={form.values.selectedProducts[index]?.numberOfFreeUnits ?? ""}
              helperText={
                form.errors.selectedProducts !== undefined &&
                form.errors.selectedProducts[index] !== undefined &&
                // @ts-ignore
                form.errors.selectedProducts[index]["numberOfFreeUnits"]
              }
              error={
                form.touched.selectedProducts &&
                Boolean(
                  form.errors.selectedProducts !== undefined &&
                    form.errors.selectedProducts[index] !== undefined &&
                    // @ts-ignore
                    form.errors.selectedProducts[index]["numberOfFreeUnits"],
                )
              }
              {...commonInputProps}
            />
          ) : (
            <Typography textAlign="center">-</Typography>
          )}
        </SelectedProductTableCell>
      )}
      <SelectedProductTableCell emphasizeRightBorder={emphasizeRightBorder} align="center">
        <CurrencyRenderer currency={form.values.currency} value={calculateSubTotal(product)} />
      </SelectedProductTableCell>
    </TableRow>
  );
};

const SelectedProductTableCell = styled(TableCell, {
  shouldForwardProp: propName => propName !== "hideRightBorder" && propName !== "emphasizeRightBorder",
})<{
  hideRightBorder?: boolean;
  emphasizeRightBorder?: boolean;
}>(({ theme, align, hideRightBorder, emphasizeRightBorder }) => ({
  textAlign: align ? align : "center",
  borderRight: hideRightBorder
    ? undefined
    : emphasizeRightBorder
    ? `2px solid ${theme.palette.common.grey4}`
    : `1px solid ${theme.palette.divider}`,
}));

const ExistingStockSelectedProductsTable = ({
  singles,
  nonSingles,
  form,
  onEditProduct,
  orgIsWholesaler,
}: SelectedProductsTableProps) => {
  return (
    <FieldArray
      name="selectedProducts"
      render={({ remove }) => (
        <Stack spacing={5}>
          {singles.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <SelectedProductTableCell align="left">Product</SelectedProductTableCell>
                    <SelectedProductTableCell hideRightBorder>
                      <Stack justifyContent="center" alignItems="center" direction="row" spacing={2}>
                        <Box>{`${orgIsWholesaler ? "Purchase" : "Lowest wholesale"} price per unit`}</Box>
                        {!orgIsWholesaler && (
                          <InfoTooltip
                            title={
                              <>
                                <Typography display="inline">
                                  {"For finished stock you have purchased, this is the"}
                                </Typography>
                                <Typography sx={{ fontWeight: "bold" }} display="inline">
                                  {" lower "}
                                </Typography>
                                <Typography display="inline">
                                  of the purchase price you paid and the lowest price that you sell to a wholesale
                                  customer. If this is finished stock that you have manufactured, this is the lowest
                                  price you sell to wholesalers / distributors.
                                </Typography>
                              </>
                            }
                          />
                        )}
                      </Stack>
                    </SelectedProductTableCell>
                    <SelectedProductTableCell>Number of units</SelectedProductTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {singles.map(({ product, idx }) => (
                    <NonFreeStockSelectedProductsTableRow
                      key={product.productCode}
                      form={form}
                      product={product}
                      index={idx}
                      onEdit={() => onEditProduct(product.productCode)}
                      onDelete={() => {
                        remove(idx);
                      }}
                      selectedColumns={[]}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {nonSingles.map((group, index) => {
            return (
              <NonSinglesStockSelectedProductsTable
                key={index}
                topLevelUnitType={group[0].topLevelUnitType}
                form={form}
                onEditProduct={onEditProduct}
                onDeleteProduct={remove}
                products={group.map(item => ({
                  product: assertNotUndefined(item.product),
                  idx: item.idx,
                }))}
                quantityUnit={group[0].quantityUnit}
              />
            );
          })}
        </Stack>
      )}
    />
  );
};

interface NonSinglesStockSelectedProductsTable {
  topLevelUnitType: TopLevelUnitType;
  form: FormikProps<SetupNewDealRequestFormValues>;
  onEditProduct: (productCode: string) => void;
  onDeleteProduct: (idx: number) => void;
  orgIsWholesaler?: boolean;
  products: { product: ProductLineItemFormData; idx: number }[];
  quantityUnit: BulkQuantityUnitType;
}
const NonSinglesStockSelectedProductsTable = ({
  topLevelUnitType,
  form,
  onEditProduct,
  onDeleteProduct,
  orgIsWholesaler,
  products,
  quantityUnit,
}: NonSinglesStockSelectedProductsTable) => {
  return (
    /* Use product length as key to refresh paginated table when more products are added.
     * This is because paginated table has an internal state that is not updated when the
     * initialRows prop changes.
     */
    <PaginatedTable
      key={products.length}
      initialRows={products}
      HeaderContent={
        <NonSinglesStockSelectedProductsTableHeaderRow
          topLevelUnitType={topLevelUnitType}
          orgIsWholesaler={orgIsWholesaler}
          quantityUnit={quantityUnit}
        />
      }
      BodyContent={
        <NonSinglesStockSelectedProductsTableRows
          rows={products}
          form={form}
          onEditProduct={onEditProduct}
          onDeleteProduct={onDeleteProduct}
        />
      }
      BodyBaseline={
        <NonSinglesStockSelectedProductsSubtotalRow
          form={form}
          netSubtotal={products.reduce((total, { product }) => total.add(calculateSubTotal(product)), new Big(0))}
        />
      }
    />
  );
};

function NonSinglesStockSelectedProductsTableHeaderRow(props: {
  topLevelUnitType: TopLevelUnitType;
  orgIsWholesaler?: boolean;
  quantityUnit: BulkQuantityUnitType;
}) {
  const { topLevelUnitType, orgIsWholesaler, quantityUnit } = props;
  const unitsLabel = unitsLabelForUnitType(topLevelUnitType, quantityUnit);
  const nonSingleLabel = getFormLabelForUnionField(snTopLevelUnitType, topLevelUnitType);
  return (
    <TableRow>
      <SelectedProductTableCell align="left">{`Product (${nonSingleLabel})`}</SelectedProductTableCell>
      <SelectedProductTableCell hideRightBorder>
        <Stack justifyContent="center" alignItems="center" direction="row" spacing={2}>
          <Stack justifyContent="center" alignItems="center" direction="row" spacing={2}>
            <Box>{`${orgIsWholesaler ? "Purchase" : "Lowest wholesale"} price per ${unitsLabel}`}</Box>
            {!orgIsWholesaler && (
              <InfoTooltip
                title={
                  <>
                    <Typography display="inline">{"For finished stock you have purchased, this is the"}</Typography>
                    <Typography sx={{ fontWeight: "bold" }} display="inline">
                      {" lower "}
                    </Typography>
                    <Typography display="inline">
                      of the purchase price you paid and the lowest price that you sell to a wholesale customer. If this
                      is finished stock that you have manufactured, this is the lowest price you sell to wholesalers /
                      distributors.
                    </Typography>
                  </>
                }
              />
            )}
          </Stack>
        </Stack>
      </SelectedProductTableCell>
      <SelectedProductTableCell>{unitsLabel}</SelectedProductTableCell>
      <SelectedProductTableCell>Subtotal</SelectedProductTableCell>
    </TableRow>
  );
}

function NonSinglesStockSelectedProductsTableRows(props: {
  rows: { product: ProductLineItemFormData; idx: number }[];
  form: FormikProps<SetupNewDealRequestFormValues>;
  onEditProduct: (productCode: string) => void;
  onDeleteProduct: (idx: number) => void;
}) {
  const { rows, form, onEditProduct, onDeleteProduct } = props;
  return (
    <>
      {rows.map(({ product, idx }) => (
        <NonFreeStockSelectedProductsTableRow
          key={product.productCode}
          form={form}
          product={product}
          index={idx}
          onEdit={() => onEditProduct(product.productCode)}
          onDelete={() => {
            onDeleteProduct(idx);
          }}
          selectedColumns={[]}
        />
      ))}
    </>
  );
}

function NonSinglesStockSelectedProductsSubtotalRow(props: {
  form: FormikProps<SetupNewDealRequestFormValues>;
  netSubtotal: string | number | Big;
}) {
  const { form, netSubtotal } = props;
  return (
    <TableRow>
      <SelectedProductTableCell colSpan={3} align="right" hideRightBorder>
        Net Subtotal
      </SelectedProductTableCell>
      <SelectedProductTableCell hideRightBorder>
        <CurrencyRenderer currency={form.values.currency} value={netSubtotal} />
      </SelectedProductTableCell>
    </TableRow>
  );
}

export interface SetupNewDealRequestFormValues {
  discount: Decimal;
  currency: Currency;
  selectedProducts: ProductLineItemFormData[];
}

interface SelectedProductTableRowProps {
  form: FormikProps<SetupNewDealRequestFormValues>;
  product: ProductLineItemFormData;
  selectedColumns: string[];
  onDelete: () => void;
  onEdit: () => void;
  index: number;
}

const NonFreeStockSelectedProductsTableRow = ({
  form,
  product,
  index,
  onEdit,
  onDelete,
}: SelectedProductTableRowProps) => {
  const fieldName = (name: string) => `selectedProducts[${index}].${name}`;
  const unitType = isNewProduct(product) ? product.value.unitType : product.value.value.unitType;
  const nonSingles = !isSinglesUnitType(unitType);
  const formProduct = form.values.selectedProducts[index];
  const commonInputProps: Partial<TextFieldProps> = {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      form.handleChange(e);
      // reset error of the field when user types in for better UX
      form.setFieldError(e.target.name, undefined);
    },
    onBlur: form.handleBlur,
  };

  return (
    <TableRow>
      <SelectedProductTableCell align="left">
        <ProductCellContent product={product} onDelete={onDelete} onEdit={onEdit} />
      </SelectedProductTableCell>
      <SelectedProductTableCell hideRightBorder>
        <CurrencyInputFastField
          debounced
          currency={form.values.currency}
          name={fieldName("clientSpecifiedPurchasePrice")}
          value={form.values.selectedProducts[index]?.clientSpecifiedPurchasePrice ?? ""}
          error={
            form.touched.selectedProducts &&
            Boolean(
              form.errors.selectedProducts !== undefined &&
                form.errors.selectedProducts[index] !== undefined &&
                // @ts-ignore
                form.errors.selectedProducts[index]["clientSpecifiedPurchasePrice"],
            )
          }
          helperText={
            form.errors.selectedProducts !== undefined &&
            form.errors.selectedProducts[index] !== undefined &&
            // @ts-ignore
            form.errors.selectedProducts[index]["clientSpecifiedPurchasePrice"]
          }
          {...commonInputProps}
        />
      </SelectedProductTableCell>
      <SelectedProductTableCell>
        <UnitsInputFastField
          hideUnitsLabel={nonSingles}
          debounced
          unitType={unitType}
          name={fieldName("numberOfUnits")}
          value={form.values.selectedProducts[index]?.numberOfUnits ?? ""}
          error={
            form.touched.selectedProducts &&
            Boolean(
              form.errors.selectedProducts !== undefined &&
                form.errors.selectedProducts[index] !== undefined &&
                // @ts-ignore
                form.errors.selectedProducts[index]["numberOfUnits"],
            )
          }
          helperText={
            form.errors.selectedProducts !== undefined &&
            form.errors.selectedProducts[index] !== undefined &&
            // @ts-ignore
            form.errors.selectedProducts[index]["numberOfUnits"]
          }
          {...commonInputProps}
        />
      </SelectedProductTableCell>
      <SelectedProductTableCell hideRightBorder>
        <CurrencyRenderer currency={form.values.currency} value={calculateSubTotal(formProduct)} />
      </SelectedProductTableCell>
    </TableRow>
  );
};

interface ProduceTableCellProps {
  product: ProductLineItemFormData;
  onDelete: () => void;
  onEdit: () => void;
}
const ProductCellContent = ({ product, onEdit, onDelete }: ProduceTableCellProps) => {
  const pInfo = isNewProduct(product) ? product.value : product.value.value;
  const summaryProps: OrganisationProductSummaryProps = {
    ...pInfo,
    vesselCapacity: getVesselCapacity(pInfo.vesselSize),
    abv: pInfo.alcoholByVolumePc,
    country: pInfo.countryOfOrigin,
  };

  return (
    <Stack spacing={1}>
      {isNewProduct(product) && (
        <Box>
          <Chip color="success" label="New" />
        </Box>
      )}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <OrganisationProductSummary {...summaryProps} />
        <Stack direction="row">
          {isNewProduct(product) && (
            <Tooltip title="Edit product" arrow describeChild>
              <IconButton onClick={onEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Remove product" arrow describeChild>
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};

interface ActionButtonsProps {
  onBack: () => void;
  onNext: () => void;
  disabled: boolean;
}
const ActionButtons = ({ onBack, onNext, disabled }: ActionButtonsProps) => {
  return (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" onClick={onBack}>
        Back
      </Button>
      <Button disabled={disabled} onClick={() => onNext()}>
        Next
      </Button>
    </Stack>
  );
};

const SetupStep = ({ question, children }: { question: string; children: React.ReactNode }) => {
  return (
    <Stack spacing={2}>
      <Typography>{question}</Typography>
      {children}
    </Stack>
  );
};

interface NewDealRequestProductsCsvUploadProps {
  onUploadProducts(data: ProductLineItemData[]): Promise<void>;
  onClickUploadProducts(parseResult: ParseResult<string[]>): Promise<ProcessedCsvProductData | undefined>;
  isNewStock: boolean;
}

const ProductsCsvUpload = ({
  onUploadProducts,
  onClickUploadProducts,
  isNewStock,
}: NewDealRequestProductsCsvUploadProps) => {
  const [showAlert] = useAlert();
  const [loadingUploadResult, setLoadingUploadResult] = useState<LoadingValue<ProcessedCsvProductData>>();
  const { CSVReader } = useCSVReader();

  const handleUpload = useCallback(
    async (parseResult: ParseResult<string[]>) => {
      setLoadingUploadResult({ state: "loading" });
      const loadingUploadFile = await onClickUploadProducts(parseResult);

      if (loadingUploadFile) {
        setLoadingUploadResult({ state: "success", value: loadingUploadFile });
      }
    },
    [onClickUploadProducts],
  );

  const handleSubmitProducts = useCallback(
    async (products: ProductLineItemData[]) => {
      await onUploadProducts(products);
      setLoadingUploadResult(undefined);
      void showAlert({
        title: "Successful upload",
        body: "All rows, except those with errors (if we notified you of any), have been successfully uploaded.",
        severity: "success",
        showDismissButton: true,
      });
    },
    [onUploadProducts, showAlert],
  );

  return (
    <>
      <Stack alignItems="center" spacing={1}>
        <CSVReader
          onUploadAccepted={handleUpload}
          config={{
            skipEmptyLines: "greedy",
            dynamicTyping: (field: string | number) => field !== PRODUCT_CODE_COLUMN_NAME,
          }}>
          {({ getRootProps }: { getRootProps: () => ButtonProps }) => (
            <Button {...getRootProps()} startIcon={<AddIcon />}>
              Add products by CSV
            </Button>
          )}
        </CSVReader>
        <Link
          variant="big"
          color="black"
          href={
            isNewStock ? NEW_STOCK_NEW_DEAL_REQUEST_CSV_TEMPLATE_URL : EXISTING_STOCK_NEW_DEAL_REQUEST_CSV_TEMPLATE_URL
          }
          download>
          Download CSV template
        </Link>
      </Stack>

      {loadingUploadResult !== undefined && (
        <UploadedProductsDialog
          open={true}
          onSubmit={handleSubmitProducts}
          onCancel={() => setLoadingUploadResult(undefined)}
          loadingUploadResult={loadingUploadResult}
        />
      )}
    </>
  );
};

interface UploadedProductsDialogProps {
  open: boolean;
  onSubmit(products: ProductLineItemData[]): Promise<void>;
  onCancel(): void;
  loadingUploadResult: LoadingValue<ProcessedCsvProductData>;
}
const UploadedProductsDialog = ({ open, onSubmit, onCancel, loadingUploadResult }: UploadedProductsDialogProps) => {
  const getMismatchedExistingProducts = useCallback((products: WithCsvRowNumber<CsvProductLineItemData>[]) => {
    return products.filter(products => products.value.kind === "existing" && products.value.hasMismatchedDetails);
  }, []);
  const hasMismatchedExistingProducts = useMemo(
    () => isLoaded(loadingUploadResult) && getMismatchedExistingProducts(loadingUploadResult.value.products).length > 0,
    [getMismatchedExistingProducts, loadingUploadResult],
  );
  const [currentPage, setCurrentPage] = useState<"success" | "error">();
  const [productCodeToBooleanMap, setProductCodeToBooleanMap] = useState<Record<string, boolean>>({});
  const [errors, setErrors] = useState<WithCsvRowNumber<RowIdentifierAndErrors>[]>([]);

  const handleSubmit = useCallback(async () => {
    if (isLoaded(loadingUploadResult)) {
      await onSubmit(
        loadingUploadResult.value.products
          .filter(p => !errors.find(e => p.value.productCode === e.value.rowIdentifier))
          .map(p => p.value),
      );
    }
  }, [loadingUploadResult, onSubmit, errors]);

  const handleClickNext = useCallback(async () => {
    if (isLoaded(loadingUploadResult)) {
      let incorrectProducts: WithCsvRowNumber<RowIdentifierAndErrors>[] = errors;

      for (const [code, isCorrect] of Object.entries(productCodeToBooleanMap)) {
        const productWasSelected = errors.find(e => e.value.rowIdentifier === code);
        if (!isCorrect && !productWasSelected) {
          const product = loadingUploadResult.value.products.find(p => p.value.productCode === code);
          incorrectProducts.push({
            rowNumber: assertNotUndefined(product).rowNumber,
            value: {
              rowIdentifier: code,
              errors: [
                "Product code in CSV matches incorrect product in Ferovinum. Please correct the product code in the CSV.",
              ],
            },
          });
        } else if (isCorrect && productWasSelected) {
          incorrectProducts = incorrectProducts.filter(p => p.value.rowIdentifier !== code);
        }
      }
      setErrors(incorrectProducts);

      if (incorrectProducts.length > 0) {
        setCurrentPage("error");
      } else {
        await handleSubmit();
      }
    }
  }, [loadingUploadResult, errors, productCodeToBooleanMap, handleSubmit]);

  const confirmationDialogActions = useMemo(() => {
    const disabled = isLoading(loadingUploadResult) || currentPage === undefined;
    if (currentPage === "success") {
      return {
        acceptAction: {
          title: "Next",
          onClick: handleClickNext,
          disabled,
        },
        cancelAction: {
          title: "cancel upload",
          onClick: onCancel,
          disabled,
        },
      };
    } else {
      return {
        acceptAction: {
          title: "Got it",
          onClick: handleSubmit,
          disabled,
        },
        cancelAction: hasMismatchedExistingProducts
          ? {
              title: "back",
              onClick: () => setCurrentPage("success"),
              disabled,
            }
          : undefined,
      };
    }
  }, [loadingUploadResult, currentPage, handleClickNext, onCancel, handleSubmit, hasMismatchedExistingProducts]);

  useEffect(() => {
    if (isLoaded(loadingUploadResult)) {
      const record: { [productCode: string]: true } = {};
      for (const product of loadingUploadResult.value.products) {
        record[product.value.productCode] = true;
      }
      setProductCodeToBooleanMap(record);

      if (loadingUploadResult.value.errors.length === 0 && !hasMismatchedExistingProducts) {
        handleSubmit();
      } else {
        setErrors(loadingUploadResult.value.errors);
        setCurrentPage(hasMismatchedExistingProducts ? "success" : "error");
      }
    }
  }, [handleSubmit, hasMismatchedExistingProducts, loadingUploadResult]);

  return (
    <ConfirmationDialog open={open} maxWidth="md" fullWidth title="" {...confirmationDialogActions}>
      <Loader loadingStates={[loadingUploadResult, { state: currentPage ? "success" : "loading", value: {} }]} />

      <Collapse in={isLoaded(loadingUploadResult) && currentPage !== undefined}>
        {isLoaded(loadingUploadResult) && currentPage !== undefined && (
          <>
            <Collapse in={currentPage === "success"} unmountOnExit>
              <Stack spacing={2}>
                <Typography variant="body1">
                  {
                    "These product codes exist in Ferovinum but the product details in the uploaded CSV don’t match those recorded in Ferovinum. These differences are noted in red below."
                  }
                </Typography>
                <Typography variant="body1">
                  {
                    "Click ‘Next’ to use the existing product in Ferovinum or correct the product codes in the CSV and reupload."
                  }
                </Typography>
              </Stack>
              <ProductDiffTable
                mismatchedProducts={getMismatchedExistingProducts(loadingUploadResult.value.products)}
              />
            </Collapse>
            <Collapse in={currentPage === "error"} unmountOnExit>
              <Typography variant="body1">
                Some errors were found in the below CSV rows. Please correct the information and reupload your CSV.
              </Typography>
              <UploadedProductsWithErrorsTable errors={errors.sort((a, b) => a.rowNumber - b.rowNumber)} />
            </Collapse>
          </>
        )}
      </Collapse>
    </ConfirmationDialog>
  );
};

export interface ProductDiffTableProps {
  mismatchedProducts: WithCsvRowNumber<CsvProductLineItemData>[];
}
export const ProductDiffTable = ({ mismatchedProducts }: ProductDiffTableProps) => {
  const getSummaryProps = useCallback((product: CsvProductLineItemData): ProductSummaryProps => {
    const productData = isNewProduct(product) ? product.value : product.value.value;
    return {
      ...productData,
      vesselCapacity: getVesselCapacity(productData.vesselSize),
      abv: productData.alcoholByVolumePc,
      country: productData.countryOfOrigin,
      includeAdditionalDetails: {
        productType: productData.productType,
        regionOrigin: productData.regionOrigin,
      },
      includedAlcoholDetail: productData.alcoholDetail,
    };
  }, []);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="right">CSV row</TableCell>
          <TableCell>Product in CSV</TableCell>
          <TableCell>Product in Ferovinum</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {mismatchedProducts.map(product => (
          <TableRow key={product.value.productCode}>
            <TableCell align="right">{product.rowNumber}</TableCell>
            <TableCell>
              <UploadedProductSummary
                code={product.value.productCode}
                vesselCapacity={getSummaryProps(product.value).vesselCapacity}
                csvDataFields={assertNotUndefined(product.value.csvDataFields)}
              />
            </TableCell>
            <TableCell>
              <ProductSummary {...getSummaryProps(product.value)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface UploadedProductSummaryProps {
  code: string;
  vesselCapacity?: VesselCapacity;
  csvDataFields: CsvDataField[];
}

const BASIC_PRODUCT_FIELD_NAMES = [
  "vesselSize",
  "productDate",
  "name",
  "producerName",
  "unitType",
  "productType",
  "alcoholByVolumePc",
  "countryOfOrigin",
  "regionOrigin",
] as const;

const UploadedProductSummary = ({ code, vesselCapacity, csvDataFields }: UploadedProductSummaryProps) => {
  const {
    vesselSize,
    productDate,
    name,
    producerName,
    unitType,
    productType,
    alcoholByVolumePc: abv,
    countryOfOrigin,
    regionOrigin,
  } = useMemo(
    () => keysToRecord(BASIC_PRODUCT_FIELD_NAMES, k => csvDataFields.find(field => field.key === k)),
    [csvDataFields],
  );

  const detailFields = useMemo(
    () => [productType, abv, countryOfOrigin, regionOrigin].filter(field => field !== undefined) as CsvDataField[],
    [abv, countryOfOrigin, productType, regionOrigin],
  );

  const moreDetailFields = useMemo(() => {
    const basicFieldNames = BASIC_PRODUCT_FIELD_NAMES as readonly string[];
    return csvDataFields.filter(field => !basicFieldNames.includes(field.key));
  }, [csvDataFields]);

  return (
    <Box>
      <Typography variant="body2" color="common.grey6">
        {code}
      </Typography>
      <Typography variant="body1">
        {productDate && <DataField value={productDate} />}
        {productDate && name && " | "}
        {name && <DataField value={name} />}
      </Typography>

      <Typography variant="body2">
        {producerName && <DataField value={producerName} />}
        {producerName && (vesselSize || unitType) && " | "}
        {vesselSize && (
          <DataField
            value={vesselSize}
            valueFormatter={vesselSize => `${vesselSize} ${vesselCapacity?.unitLabel ?? ""} `}
          />
        )}
        {unitType && (
          <DataField
            value={unitType}
            valueFormatter={unitType => (isValidUnitType(unitType) ? unitTypeToString(unitType) : titleCase(unitType))}
          />
        )}
      </Typography>

      <Typography variant="body2">
        {detailFields.map((field, idx) => (
          <React.Fragment key={field.key}>
            <DataField
              value={field}
              valueFormatter={field.key === "alcoholByVolumePc" ? abv => `${abv}% ABV` : undefined}
            />
            {idx !== detailFields.length - 1 && " | "}
          </React.Fragment>
        ))}
      </Typography>
      <Typography variant="body2">
        {moreDetailFields.map((field, idx) => (
          <React.Fragment key={field.key}>
            <DataField value={field} />
            {idx !== moreDetailFields.length - 1 && " | "}
          </React.Fragment>
        ))}
      </Typography>
    </Box>
  );
};

const DataField = ({ value, valueFormatter }: { value: CsvDataField; valueFormatter?: (v: string) => string }) => {
  return (
    <Box
      component="span"
      sx={{ color: theme => (value.isMismatched ? theme.palette.error.main : theme.palette.common.grey7) }}>
      {`${valueFormatter ? valueFormatter(value.value) : titleCase(value.value)}`}
    </Box>
  );
};

interface UploadedProductsWithErrorsTableProps {
  errors: WithCsvRowNumber<RowIdentifierAndErrors>[];
}

const UploadedProductsWithErrorsTable = ({ errors }: UploadedProductsWithErrorsTableProps) => {
  const getUniqueProductCodesErrors = useCallback(() => {
    // Turn errors into a map to remove duplicates
    const productCodeToRowNumberMap: Map<string, number[]> = errors.reduce((map, error) => {
      if (error.value.rowIdentifier) {
        const rowNumber = map.get(error.value.rowIdentifier) || [];
        map.set(error.value.rowIdentifier, [...rowNumber, error.rowNumber]);
      } else {
        map.set("", [error.rowNumber]);
      }
      return map;
    }, new Map());

    // Turn it back into an array to sort it by row index
    return [...productCodeToRowNumberMap.entries()].sort((a, b) => a[1][0] - b[1][0]);
  }, [errors]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Product code</TableCell>
          <TableCell align="right">CSV row</TableCell>
          <TableCell>Errors</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {getUniqueProductCodesErrors().map(product => {
          const productCode = product[0];
          const rowNumbers = product[1];
          const errorMessages = assertNotUndefined(errors.find(err => err.rowNumber === rowNumbers[0])).value.errors;
          return (
            <TableRow key={rowNumbers.join(", ")}>
              <TableCell>{productCode}</TableCell>
              <TableCell align="right">{rowNumbers.join(", ")}</TableCell>
              <TableCell>
                {rowNumbers.length === 1 ? (
                  <Stack spacing={2}>
                    {errorMessages.map((err, i) => (
                      <Typography key={err + i + rowNumbers.join(", ")}>{err}</Typography>
                    ))}
                  </Stack>
                ) : (
                  <>Product Code is entered more than once.</>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
